import {
  BoxProps,
  Center,
  CenterProps,
  Icon,
  Image,
  ImageProps,
} from "@chakra-ui/react"
import { IconType } from "react-icons"

export const BoxIcon = ({
  icon,
  size = 10,
  ...props
}: BoxProps & {
  icon: IconType
  size?: CenterProps["w"]
}): JSX.Element => {
  return (
    <Center
      flex="none"
      w={size}
      h={size}
      bgColor="gray.50"
      color="gray.500"
      borderWidth={1}
      borderColor="gray.100"
      rounded="md"
      {...props}
    >
      <Icon as={icon} w="50%" h="50%" />
    </Center>
  )
}

export const BoxImage = ({
  src,
  fallbackSrc,
  size = 10,
  imageProps,
  ...props
}: BoxProps & {
  src: ImageProps["src"]
  fallbackSrc?: ImageProps["src"]
  size?: CenterProps["w"]
  imageProps?: ImageProps
}): JSX.Element => {
  return (
    <Center
      flex="none"
      w={size}
      h={size}
      bgColor="gray.50"
      color="gray.500"
      borderWidth={1}
      borderColor="gray.100"
      rounded="md"
      {...props}
    >
      <Image
        src={src}
        w="50%"
        h="50%"
        {...imageProps}
        {...(fallbackSrc && {
          onError: (e) => (e.currentTarget.src = fallbackSrc),
        })}
      />
    </Center>
  )
}
